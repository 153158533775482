import React from "react";

const Success = () => {
  return (
    <div className="success">
      <p className="success__text">&#10004; Успешно!</p>
    </div>
  );
};

export default Success;
